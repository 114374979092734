import Image from 'next/image';

const ByeStress = () => {
    return (
            <section className="w-full text-white text-center bg-blue-stress">
                <div className=" w-full mt-20 md:mt-0">
                    <div className="pw">
                        <div className="w-full">
                            <div className="flex justify-center w-full mb-8">
                                <h2 className="xs:-mt-4 md:mt-12 mb-8 font-fredoka text-4.5xl text-center w-full">
                                    Adiós al estrés y los trámites confusos
                                </h2>
                            </div>
                        </div>
                        <div className="w-full flex md:flex-row flex-col mt-20">
                            <div className="md:w-1/4 w-full mb-6">
                                <div className="md:px-5 xl:px-5">
                                    <div className='relative w-full h-[199px]'>
                                        <Image loading="lazy"
                                            src="https://cdn.fixat.mx/assets/home/img/facil-y-rapido.png"
                                            alt="Fácil y Rápido"
                                            title="Fácil y Rápido"
                                            layout='fill'
                                            objectFit='contain'
                                        />
                                    </div>
                                    <span className="float-left w-full text-2xl font-fredoka mt-7 mb-5">
                                        Fácil y rápido
                                    </span>
                                    <p className="mb-16">Con FIXAT®, lo único que haces es ingresar tus datos y pagar tus impuestos.
                                    </p>
                                </div>
                            </div>
                            <div className="md:w-1/4 w-full mb-6">
                                <div className="md:px-5 xl:px-5">
                                    <div className='relative w-full h-[199px]'>
                                        <Image loading="lazy"
                                            src="https://cdn.fixat.mx/assets/home/img/paga-lo-justo-con-fixat.png"
                                            alt="Paga lo justo con Fixat"
                                            title="Paga lo justo con Fixat"
                                            layout='fill'
                                            objectFit='contain'
                                        />
                                    </div>
                                    <span className="float-left w-full text-2xl font-fredoka mt-7 mb-5">
                                        Económico
                                    </span>
                                    <p className="mb-16">Olvídate de pagos excesivos y cálculos mal hechos. Con Fixat® pagas lo justo.
                                    </p>
                                </div>
                            </div>
                            <div className="md:w-1/4 w-full mb-6">
                                <div className="md:px-5 xl:px-5">
                                    <div className='relative w-full h-[199px]'>
                                        <Image loading="lazy"
                                            src="https://cdn.fixat.mx/assets/home/img/fixat-respaldado-por-contadores-certificados.png"
                                            alt="Fixat está respaldado por contadores certificados"
                                            title="Seguro"
                                            layout='fill'
                                            objectFit='contain'
                                        />
                                    </div>
                                    <span className="float-left w-full text-2xl font-fredoka mt-7 mb-5">
                                        Seguro
                                    </span>
                                    <p className="mb-16">FIXAT® está respaldado por contadores éticos y profesionales que cuidan tu dinero.</p>
                                </div>
                            </div>
                            <div className="md:w-1/4 w-full mb-6">
                                <div className="md:px-5 xl:px-5">
                                    <div className='relative w-full h-[199px]'>
                                        <Image loading="lazy"
                                            src="https://cdn.fixat.mx/assets/home/img/tramites-sin-complicaciones.png"
                                            alt="Trámites sin complicaciones"
                                            title="Sin complicaciones"
                                            layout='fill'
                                            objectFit='contain'
                                        />
                                    </div>
                                    <span className="float-left w-full text-2xl font-fredoka mt-7 mb-5">
                                        Sin complicaciones
                                    </span>
                                    <p className="mb-16">Olvídate de todo y déjale tus declaraciones a los que saben.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default ByeStress;